import React from 'react';

const Footer = ({params}) => (
<div className="footer">

  <p className="copyright f-level1">2018 Developed By Ibrahim Alkhudair :)</p>

  <div className="f-level2">
    <div className="info-right">
        <h3>Blogs مقالاتي</h3>
        <ul>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
        </ul>
    </div>

    <div className="info-left">
        <h3>Projects اعمالي</h3>
        <ul>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
        </ul>
    </div>
    <div className="info-center">
        <h3>Tutorial شروحاتي</h3>
        <ul>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
          <li><a href="empty.php" target="_blank">لا يوجد</a></li>
        </ul>
    </div>
    </div>

    <div className="f-level3">
      <div className="info-center">

        <address>
          Email contact <i className="far fa-envelope"></i> : <a href="mailto:x@ibrahim1.net">x@ibrahim1.net</a> <br/> 
          https://ibrahim1.net<br/>
          Riyadh, Saudi Arabia        
        </address>
      </div>
    </div>
</div>

);

export default Footer;
