import React from 'react';
import '../css/main.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { DarkModeSwitch } from 'react-toggle-dark-mode';

function resposivefun() {
  var x = document.getElementById("selecter");
  if (x.className === "selecter"){
    x.className += " resposive";
  } else {
    x.className = "selecter";
  }
}

function closeNav(){
  var x = document.getElementById("selecter");
  x.className = "selecter";
  console.log("hi")
  
}

const Navbar = ({params}) => {
  const [isDarkMode, setDarkMode] = React.useState(true);

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
    document.body.classList.toggle(document.body.className === 'light' ? 'dark': 'light')
  };

  return (
    <div>
<div className="navbar" id="navbar">
  <ul className="selecter" id="selecter">
    <Link to="/home" onClick={() => closeNav()} style={{textDecoration: 'none'}}><li><i className="fas fa-home"></i>Home - الرئيسية</li></Link>
    {/* <li><i className="far fa-file-alt"></i> <a href="/files">PDF Files - ملفات</a></li> */}
    <Link to="/blogs" onClick={() => closeNav()} style={{textDecoration: 'none'}}><li><i className="fas fa-book" onClick={() => closeNav()}></i> Blogs - مقالاتي</li></Link>
     {/* <li><i className="far fa-file-alt"></i> <a onclick="PdfPage()">PDF Files - ملفات البي دي اف</a></li> */}
    {/* <li><i className="far fa-question-circle"></i> <a href="/ask">Ask Me - أسالني</a></li> */}
    <Link to="/works" onClick={() => closeNav()} style={{textDecoration: 'none'}}><li><i className="fas fa-wrench" onClick={() => closeNav()}></i>My Works - أعمالي</li></Link>
    <Link to="/cv" onClick={() => closeNav()} style={{textDecoration: 'none'}}><li><i className="far fa-address-card" onClick={() => closeNav()}></i>[ CV ] About Me - عني</li></Link>
    <li>
      <DarkModeSwitch
        style={{ color: 'whitesmoke' }}
        checked={isDarkMode}
        onChange={toggleDarkMode}
        size={30}
      />
    </li>


    <div className="Name-tag"></div>
    <a href="javascript:void(0);" className="icon" onClick={() => resposivefun()}>
    <i className="fa fa-bars"></i></a>
  </ul>
</div>
    <div style={{backgroundColor: '#2e2d2d', height: '5px'}}></div>
    </div>
  )
}

export default Navbar;
