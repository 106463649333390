import React from 'react';

const Skillspanel = ({params}) => (
    <div className="card">
    <div className="card-header">
      <p className="ar">مهارات الصلبة</p>
      <p className="en">Hard Skills</p>
    </div>
    <div className="card-body">
      <p>Front End: HTML, CSS</p>
      <div className="process" style={{background: '#2e2e2e', color: 'white', width: '100%'}}>100%</div>
      <p>Javascript (React Native/ React js)</p>
        <div className="process" style={{background: '#2e2e2e', color: 'white', width: '90%',}}>90%</div>
      <p>Javascript (Node jsExpressJS)</p>
        <div className="process" style={{background: '#2e2e2e', color: 'white', width: '85%',}}>80%</div>
      <p>SQL (mysql phpmyadmin, sqllite)</p>
        <div className="process" style={{background: '#2e2e2e', color: 'white', width: '85%',}}>85%</div>
      <p>NoSQL (MongoDB)</p>
      <div className="process" style={{background: '#2e2e2e', color: 'white', width: '85%',}}>85%</div>
      <p>Python</p>
      <div className="process" style={{background: '#2e2e2e', color: 'white', width: '90%',}}>90%</div>
      <p>Java</p>
        <div className="process" style={{background: '#2e2e2e', color: 'white', width: '90%',}}>90%</div>
      <p>PHP</p>
        <div className="process" style={{background: '#2e2e2e', color: 'white', width: '70%',}}>70%</div>
      <p>Photoshop designer</p>
        <div className="process" style={{background: '#2e2e2e', color: 'white', width: '70%',}}>70%</div>
    </div>
  </div>

);

export default Skillspanel;
