import React from 'react';
import PostInput from '../../Shared/PostInput';



const PostWorks = ({params}) => {
  
  
  return (
      <div className="content" id ="content">
            <div className="panel-center">
                <input type="text" placeholder="Titile here.." className="inputEnter"/>
                <input type="date" placeholder="Titile here.." className="inputEnter"/>
                <textarea placeholder="please enter your text here"></textarea>
                <PostInput />

                {/* <div className="card" style={{height: '100%'}}>
                  <div className="card-header">
                    <p className="ar">[ IBRAHIM works post ]</p>
                  </div>

                  <div className="card-body" style={{textAlign: 'left'}}>
                  </div>

                </div> */}

              </div>

              <div className="panel-left">
                <button>Publish</button>
                <button>Save as draft (unPublish)</button>
                <button>Publish</button>
                <button>Delete the post</button>
                <button>PIN the post</button>
                <button>unPIN the post</button>

              </div>

            <div className="panel-right">
                <h3>Status: Not published</h3>
            </div>
           
          </div>
  )
}

export default PostWorks;
