import React from 'react';

const NotFound = ({params}) => (
    
<div className="content" id ="content">
        <div className="panel-center">
            <div className="card">

              <div className="card-body">
                    <h1>Not Found 404</h1>
              </div>
            </div>
          </div>
        <div className="panel-right">

        </div>
        <div className="panel-left">
          <div className="card">

          </div>
        </div>
      </div>
);

export default NotFound;
