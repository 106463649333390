import React, {useState, useEffect} from 'react';
import SoicalPanel from '../../Shared/SoicalPanel';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import SearchBar from '../Components/SearchBar';
var jp = require('jsonpath');

const Myworks = ({params}) => {
    const [works, setWorks] = useState([])
    const [loading, setLoading] = useState(true);

   
    useEffect(() => {
        axios.get('https://api.jsonbin.io/v3/b/6547b86a12a5d37659953c03?meta=true',{
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'})
        .then(function (response) {
          // handle success
          const newData = jp.query(response.data, "$.record.works[*]");
          setWorks(newData)
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);

        })
        .finally(function () {
          // always executed
          setLoading(false)
        });
      }, [])


    
    return (
    
        <div className="content" id ="content">
                <div className="panel-center">
                  <SearchBar placeholder="Search for a work"/>
                  <Link to="/postwork">
                    <button style={{width: '50%'}}>Add New work <i class="fa fa-eye" aria-hidden="true"></i> </button>
                  </Link>

                  {/* <img src="" /> */}
                <center>
                  {loading && <div class="lds-ripple"><div></div><div></div></div>}
                  </center>
                  
                  {/* <img src={require('../img/work1061/work1061_1.jpeg')} alt="k"/> */}
                    {
                        works && works.map((m, index) => 
                            <>
                                <hr></hr>
                                
                                    <div className="card" key={m.id}>
                                        <div className="card-header">
                                            <p className="ar">[ #{index+1} - {m.title} ]</p>
                                        </div>
                                        <div className="card-body" >
                                            <h3>{m.title}</h3>
                                            {/* {m.images && m.images.map(k => 
                                                <img src={k} alt="k" />
                                              )} */}
                                            <div dangerouslySetInnerHTML ={{__html: m.brief}} ></div>
                                            <Link to={`/works/view/${m.id}`}><button style={{width: '50%'}}>More details <i class="fa fa-eye" aria-hidden="true"></i> </button></Link>
                                            <Link to={`/works/view/${m.id}`}><button style={{width: '50%'}} >Edit <i class="fa fa-eye" aria-hidden="true"></i> </button></Link>
                                            <Link to={`/works/view/${m.id}`}><button style={{width: '50%'}} >Delete<i class="fa fa-eye" aria-hidden="true"></i> </button></Link>

                                            <br />
                                            <small style={{color: 'gray'}}>{m.date}</small>
                                            {/* <h3>جاري العمل عليه</h3> */}
                                        </div>
                                    </div>
                                 <hr></hr>
                            </>
                        )
                    }
                  </div>
                <div className="panel-right">
        
                </div>
                <SoicalPanel/>
              </div>
        );
}

export default Myworks;
