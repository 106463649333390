import React from 'react';

const Auth = () => (
    <div>
        <div className="panel-center">
            <h2>Login admin ibrahim1</h2>
            <input type="text" placeholder="username" />
            <input type="password" placeholder="password" />
            <button>Login</button>
        </div>
    </div>
);

export default Auth;
