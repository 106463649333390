import React, {useState} from 'react';
import { Document, Page } from 'react-pdf';
import MYPDF from "../files/IbrahimAlkhudair_Software_developer_Resume.pdf"
const PDFViewer = () => {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({numPages}) {
      setNumPages(numPages);
    }
    return (
        <div >
            <Document file={MYPDF} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
            </Document>
            <p>
            Page {pageNumber} of {numPages}
            </p>
      </div>
    );
};
export default PDFViewer;