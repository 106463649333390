import React from 'react';
import PDFViewer from '../Shared/PdfFile';

const About = ({params}) => (
    
<div className="content" id ="content">
        <div className="panel-center">
            <div className="card">
              <div className="card-header">
                <p className="ar">[ IBRAHIM web CV ]</p>
              </div>
              <div className="card-body">
                {/* <button className="btn">View CV Pdf</button> */}
                <PDFViewer />
                {/* <iframe src="./files/IbrahimAlkhudair_Software_developer_Resume.pdf" width="100%" height="500px" /> */}

                <br />
                {/* <h3>جاري العمل عليه</h3> */}
                <p>:للأطلاع على المزيد من التفاصيل تواصل معي عبر </p>
                <h3>x@ibrahim1.net</h3>
              </div>
            </div>
          </div>
        <div className="panel-right">

        </div>
        <div className="panel-left">
          <div className="card">

          </div>
        </div>
      </div>
);

export default About;
