import React, {useState, useEffect, useLayoutEffect} from 'react';
import SoicalPanel from '../Shared/SoicalPanel';
import { IoMdArrowRoundBack } from 'react-icons/io';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import axios from 'axios';
var jp = require('jsonpath');

const Subwork = ({params}) => {
  let { id } = useParams();
  const [work, setWork] = useState({})
  const [isLoading, setLoading]= useState(true);
useEffect(() => {
  axios.get('https://api.jsonbin.io/v3/b/6547b86a12a5d37659953c03?meta=true',{
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    })
  .then(function (response) {
    // handle success
    const newData = jp.query(response.data, `$.record.works[?(@.id==${id})]`);
    setWork(newData[0])
      setLoading(false)
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
      setLoading(false)
  });
}, [])

useLayoutEffect(() => {
  window.scrollTo(0, 0)
});
    return (
          <div className="content" id ="content">
          <div className="panel-center">
              <Link to="/works"><h1><IoMdArrowRoundBack /></h1></Link>
              <h3>{work.title}</h3>
              <div className="card">
                <div className="card-body">
                        {isLoading ? (         <center>
                  {isLoading && <div class="lds-ripple"><div></div><div></div></div>}
                  </center>) : work.title ? <div style={{textAlign: 'left'}}>
                  {work.images && work.images.map(k => 
                                                <img src={k} alt="k"/>
                                              )}
                    <div  dangerouslySetInnerHTML ={{__html: work.content}}></div></div>:  <h3 style={{color: 'red'}}>الرجاء التآكد من الرابط</h3> }
                        </div>
                    </div>
                    <small style={{color: 'gray'}}>{work.date}</small>

                  </div>
                  <div className="panel-right">
        
                    </div>
                    <SoicalPanel/>
              </div>
        );
} 

export default Subwork;
