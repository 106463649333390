// import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import Footer from './Shared/Footer'
import Header from './Shared/Header';
import Navbar from './Shared/Navbar';
import About from './Pages/About';
import Blogs from './Pages/Blogs';
import Myworks from './Pages/Works/Myworks'
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import Subwork from './Pages/Subwork';
import NotFound from './Pages/NotFound';
import './css/main.css?test=1920';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { pdfjs } from 'react-pdf';
import PostWorks from './Pages/Works/PostWork';
import Auth from './Pages/Auth';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();



function App() {
  return (
    <>
      <Header />
      <Navbar />
      {/* <About /> */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/works" component={Myworks} />
        <Route exact path="/postwork" component={PostWorks} />

        <Route exact path="/works/view/:id" component={Subwork} />
        <Route exact path="/blogs" component={Blogs} />


        <Route exact path="/cv" component={About} />
        <Route exact path="/auth" component={Auth} />


        <Route path="*" component={NotFound} />
      </Switch>
      {/* <Blogs /> */}
      <Footer />
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
