import React from 'react';
import '../css/main.css';
import { TypeAnimation } from 'react-type-animation';

const Header = ({params}) => (
    <div>
      
<div class="header center">
  <i class="fas fa-laptop-code" style={{fontSize: '20px', boxShadow: '10px 10px 50px white'}}></i>
  <h2 style={{fontFamily:'JF-Flat'}}>
  <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'Ibrahim Alkhudair',
        2000, // wait 1s before replacing "Mice" with "Hamsters"
        'Software developer',
        2000,
        'Web developement',
        2000,
        'System developement',
        2000
      ]}
      wrapper="span"
      speed={50}
      // style={{ fontSize: '2em', display: 'inline-block' }}
      repeat={Infinity}
    />
    </h2>
  <p>اللهم ارزقنا نجاحاً في كل أمر، ونيلاً لكل مقصد، وارزقنا القمة في درجات العلم</p>

  {/* <p>24 years old , KSU Student , Desginer , Programmer Soon..</p> */}
</div>

    </div>
);

export default Header;
