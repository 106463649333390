import React from 'react';
import SearchBar from './Components/SearchBar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

const blogs = [
  { 
    id: 1,
    title: 'Data structures', 
    brief: 'wadokwoakdowad',
    isSeries: false,
  },
  {
    id: 2,
    title: 'Baisc web', 
    brief: 'wadokwoakdowad',
    isSeries: false,
  },
  {
    id: 3,
    title: 'Google', 
    brief: 'wadokwoakdowad',
    isSeries: false,
  },
  {
    id: 4,
    title: 'Hi', 
    brief: 'wadokwoakdowad',
    isSeries: false,
  }
]
const Blogs = ({params}) => {
  
  
  return (
      <div className="content" id ="content">
         <div className="panel-left">
              <div className="side-nav">
                <ul className="sub-side">
                    {/* <li>
                      <details>
                          <summary>Epcot Center</summary>
                          <ol>
                            <li>one</li>
                            <li>two
                              <ol>
                                <li>two.one</li>
                                <li>two.two</li>
                                <li>two.three</li>
                              </ol>
                            </li>
                            <li>three
                              <ol>
                                <li>three.one</li>
                                <li>three.two
                                  <ol>
                                    <li>three.two.one</li>
                                    <li>three.two.two</li>
                                  </ol>
                                </li>
                              </ol>
                            </li>
                            <li>four</li>
                          </ol>
                        </details>
                    </li> */}

                    
                    <li>كيف تسوي بوت واتس اب </li>
                    <li>Hi</li>
                    <li>Hi</li>

                </ul>

                
                </div>
            </div>

            <div className="panel-center">
                <search>
                  <form>
                    <SearchBar placeholder="Search for a blog"/>
                  </form>
                </search>
                <Link to="/postwork"><button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i> Add a new blog+</button></Link>

                <div className="card">
                  <div className="card-header">
                    <p className="ar">[ IBRAHIM blogs ]</p>
                  </div>

                  <div className="card-body" style={{textAlign: 'left'}}>
                    <main>
                      {
                        blogs.map(m => 
                          <article key={m.id}>
                            <h2>{m.title}</h2>
                            
                            <p>{m.brief} <button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i>  View more details</button><button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i>  Edit blog</button><button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i>  Delete blog</button></p>
                          </article>   
                        )
                      }
                    </main>

                  </div>

                </div>

              </div>

            <div className="panel-right">
                      
            </div>
           
          </div>
  )
}

export default Blogs;
